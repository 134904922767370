import './App.css';

function App() {
  return (
    <div>
    <header className="sticky-header">
      <nav>
          <ul>
              <li><a href="#title">Home</a></li>
              <li><a href="#missionstatement">About</a></li>
              <li><a href="#footer">Contact</a></li>
          </ul>
      </nav>
  </header>
  <main>
      <section id="title">
          <h1>Welcome to LCD Entertainment</h1>
      </section>

      <section id="missionstatement">
          <p>
              Our mission at LCD Entertainment is to redefine what it means to be a record label in the 21st century. We are dedicated to embracing new technologies, creative approaches, 
              and business models that challenge the status quo and drive meaningful change. We believe in the power of community and strive to be a positive force for change in the world. 
              Which is why our priority is to empower individuals to push creative boundaries, realize their full potential, and make a positive impact through uplifting others, bridging communities, 
              and pursuing passions. We aim to create a better world founded on acceptance, energy, and expression.
          </p>
      </section>
  </main>

      <footer className="sticky-footer">
          <p>Copyright LCD Entertainment © 2023</p>
      </footer>
      </div>
  );
}

export default App;
